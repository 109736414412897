import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import iphonemockup1 from "../images/iphonemockup1.png"
import iphonemockup2 from "../images/iphonemockup2.png"
import iphonemockup3 from "../images/iphonemockup3.png"
import 'bootstrap/dist/css/bootstrap.min.css'
import YN from "../images/YN.png"
import AlagbaTe from "../images/alagbate.png"
import Ashinao from "../images/ashinao.png"
import AfricanMask from "../images/africanmask.png"
import AfricanMask2 from "../images/africanmask2.png"
import AfricanMask3 from "../images/africanmask3.png"
import { SocialIcon } from 'react-social-icons'


function Home(){
    return (
  
        
        <div className="container-fluid" >



            <div className="subcontainer">

                    <br/>
                    <br/>
                    <br/>

                  
                         

           
                <div class="row" id="subcontainer-row">
                
                    <div class="col-sm" >
                     <br/>
                     <br/>
            
                    <div className="headingB">
                             YOONUU IS A GROWING COMMUNITY OF AMBITIOUS QUEER PROFESSIONALS AND ACTIVISTS IN THE AFRICAN DIASPORA 
                    </div>
              
                    </div>
                 
                    <div class="col-sm" >
                        <br/>
                        <br/>
                        <br/>
                        <img id="phone" src={iphonemockup1} alt="" width="260" class="d-block m-auto"/>
              
                    </div>
                    <div class="col-sm" >
                        <br/>

                        <img id="phone" src={iphonemockup2} alt="" width="300" class="d-block m-auto"/>
                        <h4 className="download"><b>Download iOS App</b></h4>
                        <p className="download">Available Soon on AppStore</p>
                    </div>
                    <div class="col-sm" >
                        <br/>
                        <br/>
                        <br/>
                        <img id="phone" src={iphonemockup3} alt="" width="260" class="d-block m-auto"/>
                       
                    </div>
                    
       
    
                </div> 

            </div>

            

        </div>
      
  
  
    );
}
  
export default Home;