import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import 'bootstrap/dist/css/bootstrap.min.css'

function PrivacyPolicy(){
    return (
  
        
        <div className="container-fluid ">

            

            <div class="row justify-content-md-center" id="subcontainer-row">

                <div class="col-sm-5 mr-3">
                    <br/>
                    <div className="headingB"> PRIVACY POLICY </div>
                </div>

                <div class="col-sm-7">
                    <br/>
                    <h3><b>Introduction</b></h3>

                    <p>
                    Effective Date: The earlier of 2023-06-22 or user acknowledgment.

                    Welcome to YooNuu's Privacy and Cookie Policy ("Policy"). All references in this policy to "YooNuu", "we", "us", or "our", refer to YooNuu LLC, its parent 
                    companies, and its subsidiaries such as YooNuu Canada Inc. This Policy explains how YooNuu LLC processes and puts you in control of your Personal Information. 
                    At YooNuu, "Personal Information" means any information that allows someone to identify, or reasonably link to, you directly or indirectly. When we describe 
                    "processing" of Personal Information, we mean how we collect, use, share, and retain Personal Information.
                    </p>





                    <h5><b>Our Commitment to Privacy.</b></h5>
                    <p>
                    As a social networking application, the YooNuu Properties allows you to share information about yourself with us, YooNuu's service providers who 
                    help us provide our Properties, and other YooNuu users, including your precise location and information that may be deemed "sensitive" or "special category" 
                    in certain jurisdictions such as ethnicity. Our goal is to put you in control of as 
                    much of the Personal Information that you share within the YooNuu Properties as possible. We recommend you read this Privacy and Cookie Policy and our Terms 
                    and Conditions of Service carefully. 

                    YooNuu LLC is a U.S.-based Company. By using the YooNuu Properties, you are transmitting information to YooNuu in the United States and its service providers 
                    (in the United States and other third countries). If you are a resident of another country, note that the United States and other third countries may not afford 
                    the same privacy protections as your country of residence.

                    Permissible Age. The YooNuu Properties are intended only for users who are legal adults, generally eighteen (18) years of age and older. If you are aware that 
                    a child or minor has submitted Personal Information on the YooNuu Properties, please contact us by either using the in-app reporting tool (click here for more 
                    information) or by submitting a request outside of your YooNuu account by clicking here, so that we can take appropriate action.

                    U.S. State Residents (as allowed under applicable laws). Please see Your U.S. State Privacy Rights to learn about the specific rights that may be afforded to 
                    you under applicable U.S. state privacy laws.
                    </p>

                    <h5><b>YooNuu LLC is a U.S.-based Company.</b></h5>
                    <p>By using the YooNuu Properties, you are transmitting information to YooNuu in the United States and its service providers (in the United States and other 
                        third countries). If you are a resident of another country, note that the United States and other third countries may not afford the same privacy protections 
                        as your country of residence.</p>

                    <h5><b>Permissible Age.</b></h5>
                    <p> The YooNuu Properties are intended only for users who are legal adults, generally eighteen (18) years of age and older. If you are aware that a child or 
                        minor has submitted Personal Information on the YooNuu Properties, please contact us by either using the in-app reporting tool (click here for more 
                        information) or by submitting a request outside of your YooNuu account by clicking here, so that we can take appropriate action.</p>

                    <h5><b>U.S. State Residents (as allowed under applicable laws).</b></h5>
                    <p> Please see Your U.S. State Privacy Rights to learn about the specific rights that may be afforded to you under applicable U.S. state privacy laws.</p> 

                
                    <h3><b>PERSONAL INFORMATION WE COLLECT</b></h3>

                    When you use the YooNuu Properties, we collect Personal Information about you, the majority of which you choose to provide. We may collect data about your 
                    interactions, use, and experiences with our Properties and receive information from third-parties.

                    <h5><b>Personal Information You Are Required to Provide.</b></h5>
                    <p>For safety and secuity reasons, when you set up an account, we require that you provide Information such as email, profile picture, a selfie (for verification purposes)
                         and at least two social media links.</p>

     

                    <h5><b>Note on "sensitive" Personal Information.</b></h5> 
                    <p>In some jurisdictions and under some regulations, information that may reveal an individual's racial or ethnic origin or details on their health or sex 
                        life/sexual orientation, among other data, may be considered "sensitive" or "special category" Personal Information.</p>
                    
                    
                    <h5><b>A note on location information.</b></h5> 
                    <p>We collect and display your location (city,state,country) on your profile however other users will not know your exact location.
                    </p>

                    <h5><b>Personal Information We Receive From Your Device When You Use The YooNuu Properties.</b></h5> 
                    <p>
                        To maintain and improve the YooNuu Properties, we collect Personal Information such as user activity, hardware and software information, cookies, 
                        and leverage other technologies such as web beacons, software development kits (SDKs), local storage, and log files.
                    </p>
                    
                    
                    <h5><b>Personal Information We Receive From Third-Parties.</b></h5>
                    <p> 
                        YooNuu collects limited information from third-parties. For example, we may collect Personal Information from payment processors, app usage and website tracking 
                        information partners, authentication partners (e.g., Google and Facebook), machine learning partners, and our consent management platform provider.
                    </p>





                    <h3><b>WHAT WE DO WITH YOUR PERSONAL INFORMATION</b></h3>

                    The main reason we use your Personal Information is to enable the YooNuu Properties, such as providing you with core features of the Services, providing you 
                    with support, and supporting platform safety. We only process your Personal Information in accordance with specific purposes and legal bases where required. 

                    In general, we use your Personal Information to:

                        Provide you with the Services associated with your YooNuu account.
                        Answer your inquiries and provide you with support.
                        Improve our Properties and support user safety within the Services (including machine learning and automated decision-making).
                        Protect our interests as a company.
                        Allow you to enjoy certain Service and Property features (including machine learning and automated decision making - click here for more information).
                        Conduct surveys or obtain your voluntary feedback about our Properties.
                        Respond to legal requests from law enforcement agencies, regulators, and other third-parties to comply with "Legal Obligations" (as defined below).



                    <h3><b>HOW WE SHARE YOUR PERSONAL INFORMATION</b></h3>

                    <p>
                    To enable you to make meaningful connections, YooNuu allows you to share information about yourself with the YooNuu Community and third-parties in 
                    connection with our providing you with the YooNuu Properties as described in this Policy. In general, we share your Personal Information in the 
                    following ways:
                    </p>

                        <h5><b>The YooNuu Community.</b></h5>
                        <p>
                        The YooNuu Services are intended to be a safe space where you can discover, navigate, and interact with others in the YooNuu Community. 
                        As part of the Services, YooNuu enables you to share your Profile Information, location, and other information with the YooNuu Community.
                        </p>


                        <h5><b>Business Transactions.</b></h5> 
                        <p>If another company acquires, or plans to acquire, our company, business, or assets, that company will possess the Personal Information collected 
                            by us and will assume the rights and obligations regarding your Personal Information as described in this Policy.
                        </p>

                        <h5><b>Legal Obligations.</b></h5> 
                        <p>We may access, preserve and disclose Personal Information to investigate, prevent, or take action in connection with: (i) enforcement of the YooNuu 
                            Terms and Conditions of Service; (ii) claims that any content or behavior violates our policies or the rights of third-parties; (iii) requests for 
                            customer service; (iv) technical issues; (v) protecting the rights, property or personal safety of YooNuu, its users or the public; (vi) lawful legal 
                            requests including subpoenas, warrants, or court orders or in connection with any legal process; (vii) establishing or exercising our legal rights or 
                            defending against legal claims; (viii) tax and accounting purposes; or (ix) as otherwise required by law, collectively "Legal Obligations".
                        </p>



                        <h3><b>HOW LONG WE KEEP YOUR PERSONAL INFORMATION</b></h3>

                        <p>

                        We retain your Personal Information for no longer than is necessary to fulfill the purposes for which the information was collected or as otherwise 
                        permitted or pursuant to Legal Obligations or pursuant to the YooNuu Terms and Conditions of Service. We also 
                        retain a device identifier (i.e., IDFV and Android ID) and limited log information for up to a period of two years following account deletion to support 
                        internal reporting, metrics and statistics. Personal Information may be retained for longer periods of time if required for legal or security purposes. 
                        For example, YooNuu may retain some account information, a device identifier (i.e., IDFV and Android ID), and limited log information for accounts that 
                        have been banned or that violate the YooNuu Terms and Conditions of Service and/or YooNuu Community Guidelines. In some circumstances, YooNuu may also 
                        retain chat messages used for reporting violative behavior resulting in an account's ban.

                        Server-side and Local Storage: YooNuu is transitioning to the storage of chat messages on its servers to support the use of the YooNuu Services including 
                        enhanced safety features and an improved user experience across multiple platforms and devices, which will result in a longer retention in compliance with 
                        our data retention policy. However, chat messages, including images and recordings, may still be retained locally on user devices and may still be retained 
                        on the recipient's device even after the information is no longer available on YooNuu's servers.
                        </p>











                        <h3><b>YOUR CHOICES AND RIGHTS</b></h3>

                        <p>Where your jurisdiction's law allows, you can make the following choices and exercise your rights regarding your Personal Information held by YooNuu:</p>

                            <h5><b>Access & Portability. </b></h5>
                            <p>
                            You may request that we provide you with information about our processing of your Personal Information and provide you with a machine-readable copy of 
                            your Personal Information. We may not be able to provide some elements of information if that information would also reveal Personal Information about 
                            another person or if we are legally prevented from disclosing such information. For more information on how to download your Personal Information from 
                            YooNuu, click here. If you have questions regarding your Access & Portability output, reach out to us here.
                            </p>

                            <h5><b>Delete Account and Related Information. </b></h5>
                            <p>
                            You may delete your account and related Profile Information at any time. After deletion, your Personal Information will be deleted from YooNuu's systems 
                            in accordance with this Policy. There are circumstances where some or all of your information may be retained after your request (such as for Legal 
                            Obligations, moderation review, dispute resolution, and other matters in accordance with this Policy). For more information on how to delete your account, 
                            click here.
                            </p>
                            
                            <h5><b>Correct Profile Information. </b></h5>
                            <p>
                            By creating an account, you are providing us your Personal Information for your YooNuu Profile. You can review and change your Profile Information at any 
                            time by accessing the "Edit Profile" section of the YooNuu Services.
                            Behavioral Advertising On Websites, Third-Party Platforms, and the YooNuu Services. You can opt-out of the sharing of your Personal Information 
                            (or the "sale" of your Personal Information as the practice may be referred to in some jurisdictions) with our Ad / Marketing Partners for behavioral 
                            advertising purposes. Please note that opting-out of behavioral advertising does not mean that you will not receive advertising while using the free 
                            version of YooNuu Services. It will, however, exclude you from interest-based advertising as provided by the applicable choice mechanisms. [LEARN MORE] 
                            For more information on how to opt out of behavioral advertising on the various YooNuu Properties, click here.
                            </p>

                            <h5><b>Promotional Emails. </b></h5>
                            <p>
                            You may choose to allow us to use your email address to send free newsletters, surveys, offers, and other promotional materials related to YooNuu and/or 
                            the YooNuu Properties, as well as targeted offers from third-parties. You can stop receiving promotional emails by following the unsubscribe instructions 
                            in emails that you receive. If you unsubscribe from receiving such promotional emails, we may still send you service-related communications, and our 
                            applications will continue to deliver you in-app messages.
                            </p>
                            
                            <h5><b>Third-Party Tracking. </b></h5>
                            You may opt-out of Google Analytics' collection and use of your Personal Information on the YooNuu Site by following the instructions here to download an 
                            opt-out browser add-on.
                            
                            <h5><b>Restrict Processing. </b></h5>
                            <p>
                            Under certain circumstances, you may limit the way we process your Personal Information (e.g., through controls provided by your device's operating system, 
                            like "Location" (defined below) access, Location granularity, and opt-ing out of the use of your Ad ID for behavioral advertising).
                            </p>

                            <h5><b>Withdraw Consent. </b></h5>
                            You may withdraw consent that you have previously given us at any time. Revocation of your consent will only apply to future processing activities and will 
                            not apply retroactively. To learn more about the processing activities where YooNuu relies on your consent, refer to our Legal Bases. [LEARN MORE]
                            
                            <h5><b>Object to Processing. </b></h5>
                            <p>
                            You may object to us relying on our legitimate interests as the reason for why we process your Personal Information.
                            </p>

                            <h5><b>Non-Discrimination. </b></h5>
                            <p>
                            You are entitled to exercise the rights described above free from discrimination.
                            </p>

                        <p>You can visit our Privacy Help Center to learn more about our features that support your privacy and choices. To exercise your choice and rights or to make a 
                        request outside of your YooNuu account, click here. Statistics pertaining to YooNuu's processing of access, erasure and opt-out requests can be found here.</p>





                        <h3><b>HOW WE PROTECT YOUR PERSONAL INFORMATION</b></h3>
                        <p>
                        At YooNuu, we take the privacy and security of your Personal Information, including information considered sensitive, seriously. Our cybersecurity team 
                        actively works to maintain the integrity, confidentiality and availability of the YooNuu Properties and our policies and protocols are focused on 
                        protecting your Personal and sensitive Personal Information. We consistently strive to improve our defenses in the ongoing battle against threat actors. 
                        That said, no method of transmission over the Internet, or method of electronic storage, is fully secure, and YooNuu cannot guarantee the security of your 
                        Personal Information. Our security, safety, and privacy features are provided on an "as-is" basis. As such, their effectiveness and error-free operation 
                        cannot be guaranteed, and we cannot ensure absolute confidentiality, anonymity, or personal safety. Individuals who have specific concerns about how their 
                        data may be protected are advised not to use the Properties. In the event that we are required by law to inform you of any unauthorized access to your 
                        Personal Information, we may notify you electronically or in writing in accordance with applicable law.
                        </p>



                        <h3><b> HOW TO CONTACT US OR FILE COMPLAINTS</b></h3>

                        <h5><b>Contact Information. </b></h5>
                        <p>
                        YooNuu welcomes your comments, questions, or complaints regarding this Privacy and Cookie Policy, our use of your Personal Information, or our response to 
                        your requests regarding your Personal Information. Please contact us by email at support@yoonuu.app
                        </p>

                        POLICY CHANGES

                        The latest version of the policy available at https://www.yoonuu.app/privacyPolicy/ will govern our use of your Personal Information. YooNuu may revise 
                        this policy from time to time. If YooNuu determines, in its sole discretion, that the changes we make to this policy are material, we will notify you in 
                        advance (e.g., within the Services or via email).


                                                                                


                    
                </div>

            </div>
                        

                

                 

            

        </div>
      
  
  
    );
}
  
export default PrivacyPolicy;