import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import 'bootstrap/dist/css/bootstrap.min.css'

function TermsOfUse(){
    return (
  


        <div className="container-fluid">

        

        <div class="row justify-content-md-center" id="subcontainer-row">

                <div class="col-sm-5 mr-3">
                    <br/>
                    <div className="headingB">TERMS OF USE</div>
                </div>
                <div class="col-sm-7">
                    <br/>



                    <p>
                    THESE TERMS OF SERVICE REQUIRE BINDING ARBITRATION TO RESOLVE ANY DISPUTE OR CLAIM ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OR YOUR ACCESS 
                    TO OR USE OF THE SERVICE (AS DEFINED BELOW), AND YOU AGREE THAT ANY SUCH DISPUTE OR CLAIM WILL BE RESOLVED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, 
                    CONSOLIDATED OR REPRESENTATIVE ACTION. PLEASE REVIEW SECTION 17 CAREFULLY TO UNDERSTAND YOUR RIGHTS AND OBLIGATIONS WITH RESPECT TO THE RESOLUTION OF ANY 
                    DISPUTE OR CLAIM ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OR YOUR ACCESS TO OR USE OF THE SERVICE.


                    SOME COUNTRIES, STATES, AND REGIONS HAVE LAWS THAT CRIMINALIZE THE UNSOLICITED TRANSMISSION OF "SEXUALLY EXPLICIT VISUAL MATERIAL" INCLUDING "OBSCENE, 
                    PROFANE, INDECENT, VULGAR, SUGGESTIVE, OR IMMORAL" CONTENT, WITHOUT "EXPRESS CONSENT" OF THE RECIPIENT.


                    TO ENSURE THAT OUR MEMBERS ARE ABLE TO FREELY DISCLOSE THEIR OWN PERSONAL CONTENT TO EACH OTHER WITHOUT FEAR OF REPRISAL, PLEASE BE ADVISED THAT USE OF 
                    THE SERVICE CONSTITUTES "EXPRESS CONSENT" AS DEFINED BY APPLICABLE LAW.
                    </p>
                    
                    <h3><b>1. Introduction</b></h3>
                    <p>
                    By accessing, browsing and/or using our website(s) (each, a "Site" and collectively, the "Site(s)”), downloading, installing and/or using any mobile or 
                    web application (each an “App” and collectively, the "App(s)") or any other services or products owned, controlled or offered by YooNuu 
                    (the Site(s), the App(s) and any such other services and products are collectively referred to hereinafter as the “Service”), you acknowledge that you 
                    have read and understood and agree to be bound by these Terms of Service ("Terms").

                    If you do not agree to these Terms, then please cease using the Service immediately and you must uninstall the Service from any computer or mobile device 
                    owned or controlled by you. YooNuu (“YooNuu”) reserves the right to update or modify these Terms at any time. We recommend 
                    that you periodically check the Service for changes. By accessing, browsing and/or using any Service after these updates to these Terms have been posted, 
                    you agree to be bound by the updated Terms. THESE TERMS CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND YooNuu. In addition, the Privacy Policy governs your 
                    access to and use of the Service and the processing of certain information about you.
                    </p>
                    
                    <h3><b>2. Rights to use the service</b></h3>
                    <p>
                    Subject to your compliance with these Terms in all material respects, YooNuu grants you a limited, non-exclusive, non-transferable, 
                    non-sublicensable, revocable license to (i) access and view pages from the Service for your personal, non-commercial use only; and (ii) print a reasonable 
                    number of such pages for your personal, non-commercial use only. Your access to and use of the Service must further comply in all material respects with any 
                    instructions and guidelines (“Guidelines”) posted on and/or communicated through the Service.


                    Subject to your compliance with these Terms in all material respects, YooNuu further grants you a limited, non-exclusive, non-transferable, 
                    non-sublicensable, revocable license to install and use the Service, in machine executable object code form only, on a single compatible personal device 
                    that you own and control, solely for personal, non-commercial purposes. We may make the App(s) available for download through the iTunes App Store at 
                    https://itunes.apple.com, the Google Play Store at https://play.google.com/store/apps, or other third party app stores (each, an “App Store”). Your license 
                    to any App(s) is also governed by the applicable terms of service, policies and procedures of the App Store through which you download the App(s) (the 
                    “App Store Terms”). These Terms amend and supplement the applicable App Store Terms and to the extent that these Terms contradict or are inconsistent with 
                    the applicable App Store Terms, these Terms control and prevail. You are authorized to download the App(s) solely via the App Stores, if any, through which 
                    we make them available for download. You acknowledge that by downloading an App(s) via an App Store, you are subject to the applicable App Store Terms.


                    YooNuu reserves the right to terminate or suspend your license to use all or any portion of the Service and your other rights under these 
                    Terms at any time and for any reason, including, but not limited to, violation of these Terms, the Guidelines and/or any applicable App Store Terms. Upon 
                    such termination, all license and other rights granted to you under these Terms will immediately terminate, but all other provisions of these Terms will 
                    survive termination. You will immediately cease all use of the Service and any associated account(s) with us and remove and destroy all copies of the App(s) 
                    from your devices. We may further bar access to the Service and your account(s) and delete any and all information associated with your account(s).
                    </p>
                    
                    <h3><b>3. Eligibility to use the service</b></h3>
                    <p>
                    You must be eighteen (18) years of age or the age of majority in your jurisdiction, whichever is greater, to use the Service. By accessing or using the 
                    Service, you hereby affirm and warrant that you comply with these age requirements and can lawfully comply with these Terms. Anyone under the age of eighteen 
                    (18) or the age of majority in the applicable jurisdiction, whichever is greater, who accesses the Service in violation of these Terms will be banned from 
                    using the Service and may be the subject of additional action by YooNuu. It is your responsibility to confirm that use of the Service is 
                    permissible under the applicable laws and regulations, and you agree to fully comply with such applicable laws and regulations, where you make use of the 
                    Service. If any applicable laws and regulations prohibit your use or intended use of the Service, you may not use the Service.
                    4. 
                    </p>
                    
                    <h3><b>No warranty and limitation of liability</b></h3>
                    <p>
                    YooNuu PROVIDES THE SITE(S), THE APP(S) AND ANY OTHER SERVICE AND PRODUCT ON AN "AS IS", “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS. YooNuu DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, OR ARISING FROM STATUTE, COURSE OF 
                    DEALING, USAGE OF TRADE OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
                    NON-INFRINGEMENT, INTEGRATION, INTEROPERABILITY OR QUIET ENJOYMENT. THE YooNuu PARTIES MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE 
                    ACCURACY OR COMPLETENESS, CURRENCY, OR NON-INFRINGEMENT OF THE SERVICE OR OF THE INFORMATION OR OTHER CONTENT CONTAINED IN OR ACCESSIBLE THROUGH THE 
                    SERVICE. THE YooNuu PARTIES DO NOT WARRANT THAT THE FUNCTIONS OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE 
                    CORRECTED, OR THAT THE SERVICE OR THE SERVERS THAT MAKE THE SERVICE AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. SOME JURISDICTIONS 
                    DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO THE FOREGOING DISCLAIMER MAY NOT APPLY TO YOU.


                    YOU UNDERSTAND AND AGREE THAT YOU USE THE SERVICE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT ARISE FROM 
                    SUCH USE. UNDER NO CIRCUMSTANCES SHALL THE YooNuu PARTIES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES 
                    OF ANY KIND, OR ANY OTHER DAMAGES WHATSOEVER (HOWEVER ARISING, INCLUDING BY NEGLIGENCE), INCLUDING WITHOUT LIMITATION, DAMAGES RELATED TO USE, MISUSE, 
                    RELIANCE ON, INABILITY TO USE AND INTERRUPTION, SUSPENSION, OR TERMINATION OF ANY OF THE SERVICE AND DAMAGES RESULTING FROM LOSS OF USE OR DATA, WHETHER 
                    OR NOT THE YooNuu PARTIES HAVE BEEN ADVISED OF SUCH POSSIBILITY AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE. YOUR ONLY RIGHT WITH 
                    RESPECT TO ANY DISSATISFACTION WITH THE SERVICE SHALL BE TO TERMINATE YOUR USE OF THE SERVICE. IN NO EVENT SHALL THE YooNuu PARTIES’ TOTAL 
                    LIABILITY TO YOU FOR ALL CLAIMS ARISING UNDER THESE TERMS OR YOUR USE OF THE SERVICE EXCEED THE TOTAL AMOUNT PAID BY YOU TO YooNuu FOR USE 
                    OF THE SERVICE DURING ANY ONE MONTH. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE 
                    EXCLUSIONS MAY NOT APPLY TO YOU.

                    YOU UNDERSTAND THAT YOUR USE OF THE SERVICE MAY INVOLVE KNOWN, UNKNOWN AND/OR UNANTICIPATED RISKS THAT COULD RESULT IN INJURY OR ILLNESS, INCLUDING, BUT 
                    NOT LIMITED TO, DANGERS THAT MAY BE CAUSED BY THE ACTS OR INTENTIONS OF OTHER USERS OR OTHER THIRD PARTIES (INCLUDING WITHOUT LIMITATION, REGARDING ANY 
                    CONTENT OR MATERIALS (FOR EXAMPLE, INAPPROPRIATE OR EXPLICIT CONTENT OR MATERIALS) THAT YOU, ANOTHER USER OR A THIRD PARTY POSTS, SUBMITS, PUBLISHES, 
                    STORES, DISCLOSES, DISPLAYS, SENDS, TRANSMITS, DISSEMINATES, OR RECEIVES THROUGH THE SERVICE), OR OCCURRENCES BEYOND THE CONTROL OF YOONUU. BY USING THE SERVICE, 
                    YOU ASSUME ALL SUCH RISKS AND DANGERS AND ALL RESPONSIBILITY FOR ANY LOSSES AND/OR DAMAGES. YOU VOLUNTARILY 
                    RELEASE, WAIVE, DISCHARGE AND HOLD HARMLESS THE YooNuu PARTIES FROM ANY AND ALL CLAIMS, DEMANDS OR CAUSES OF ACTION FOR BODILY INJURY, 
                    PROPERTY DAMAGE, WRONGFUL DEATH, LOSS OF SERVICE OR OTHER CLAIMS THAT IN ANY WAY ARISE FROM OR ARE RELATED TO YOUR USE OF THE SERVICE. THE SERVICE IS 
                    INTENDED ONLY AS PERSONAL, GEOGRAPHIC LOCATION-BASED SERVICE FOR INDIVIDUAL USE. YOU ACKNOWLEDGE AND AGREE THAT YooNuu HAS OFFERED THE 
                    SERVICE, SET ITS PRICES, AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE FOREGOING WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY. YOU FURTHER 
                    ACKNOWLEDGE AND AGREE THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THESE TERMS REFLECT A REASONABLE AND FAIR ALLOCATION OF 
                    RISK BETWEEN YOU AND YooNuu, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THESE TERMS FORM AN ESSENTIAL 
                    BASIS OF THE BARGAIN BETWEEN YOU AND YooNuu. YooNuu WOULD NOT BE ABLE TO PROVIDE THE SERVICE TO YOU ON AN ECONOMICALLY 

                    REASONABLE BASIS WITHOUT THESE LIMITATIONS AND DISCLAIMERS.
                    </p>
                    
                    <h3><b>5. Changes to the service</b></h3>
                    <p>
                    YooNuu reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any portion thereof) with or 
                    without notice and without liability to you. You agree that YooNuu shall not be liable to you or any third party for any 
                    modification, suspension or discontinuance of the Service (or any portion thereof).
                    </p>

                    <h3><b>6. Our technology and content</b></h3>
                    <p>
                    YooNuu or its licensors and suppliers own all rights, title and interest in the App(s) and the software and other technology used to provide 
                    the Service and all associated intellectual property rights (“Our Technology”), and Our Technology is protected by U.S. and international copyright and other 
                    intellectual property laws and treaties. Our Technology is licensed, not sold, to you for use only under the terms and conditions of these Terms. YooNuu reserves 
                    all rights not expressly granted to you.


                    YooNuu, the YooNuu logo, YooNuu product screen shots and the YooNuu App Icon design are trademarks or the subject of other intellectual property rights of YooNuu, 
                    and may not be used without prior, express written permission from YooNuu. All other trademarks not owned by YooNuu that appear on the Service are the property of 
                    their respective owners, who may or may not be affiliated with, connected to, or sponsored by YooNuu. All content included on the Service, including all information, 
                    data, text, software, music, sounds, photographs, graphics, videos, messages, 
                    scripts, tags, compilations of the foregoing and/or other materials accessible through the Service, including all associated intellectual property rights 
                    (“Our Content”), is the property of YooNuu and/or its licensors, as applicable, and protected by United States and international intellectual 
                    property and other laws and treaties. Except as expressly permitted under these Terms, no reproduction, transmission, modification or display of any of Our 
                    Content is permitted without our prior, express written permission.
                    </p>
                    
                    <h3><b>7. Procedure for making claims of copyright infringement</b></h3>
                    <p>
                    It is our policy to respond to notices of alleged copyright infringement in compliance with applicable intellectual property law (including the Digital 
                    Millennium Copyright Act) and to terminate the accounts of repeat infringers. YooNuu, not Apple, shall be solely responsible for the 
                    investigation, defense, settlement and discharge of any such intellectual property infringement claim attributable to the Service.


                    If you believe that your content or work has been made available through the Service in a way that constitutes copyright infringement, please provide YooNuu’s 
                    Agent for Notice of Copyright Claims with the following information: (i) a physical or electronic signature of a person authorized to act 
                    on behalf of the owner of an exclusive right that is allegedly infringed; (ii) a description of the copyrighted work claimed to have been infringed, or, if 
                    multiple copyrighted works are covered by a single notification, a representative list of such works; (iii) a description of the material that you claim is 
                    infringing and where that material may be accessed within the Service; (iv) your address, telephone number and e-mail address; (v) a statement by you that 
                    you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law; and (vi) 
                    a statement from you that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the owner 
                    of an exclusive right that is allegedly infringed. Our Agent for Notice of Copyright Claims can be reached by submitting a support request at 
                    support@yoonuu.app
                    </p>


                    <h3><b>8. Fees and subscriptions</b></h3>
                    <p>
                    While the Standard Service on the Service is free for use, additional data charges may apply to you for use of the Service through your device. Additional 
                    fees may also apply for use of the Premium Version or other aspects of the Service. If there is a charge associated with a portion of the Service, you agree to 
                    pay that charge by accessing or using it. The price stated for the Service may be denominated in your local currency by iTunes App Store at 
                    https://itunes.apple.com, Google Play Store at https://play.google.com/store/apps, by YooNuu through a third party payment processing service 
                    (currently Stripe on the Android Platform; please see https://stripe.com/us/privacy), or any other applicable App Store through which you download the App(s) 
                    on your device. Fees and charges are collected by the App Store on your device. You should consult with the appropriate App Store to determine if the price 
                    charged includes all applicable taxes and currency exchange settlements. Additionally, you are welcome to contact us by submitting a support request at 
                    https://yoonuu.app/contact regarding taxes, currency exchange settlements or other billing related issues. You are solely responsible 
                    for paying such taxes or other charges. YooNuu relies on the App Store to collect subscription fees and to report on the status of subscription 
                    accounts. Your access to the Service may be suspended or cancelled if you do not make your payment on-time and/or in full. Suspension or cancellation of the Service 
                    for non-payment could result in a loss of access to and use of your account and its content. If you would like to buy the Premium Version of the Service, you do this 
                    with in-app subscriptions from the App Store on your device. You will be advised of the charge for the Premium Version of the Service before making payment and being 
                    billed. You must make all payments through a charge through the applicable App Store. You agree to pay all fees and charges incurred in connection with your use of 
                    the Service (including any applicable taxes) at the rates in effect when the charges were incurred. BETWEEN YOU AND YooNuu, YOU, AND NOT PERRY STREET 
                    SOFTWARE, ARE RESPONSIBLE FOR PAYING ANY AMOUNTS FOR THE SERVICE BILLED TO YOUR DEVICE, PAYPAL, CREDIT OR DEBIT CARD BY AN APP STORE OR THIRD PARTY WHO HAD ACCESS 
                    TO THESE ACCOUNTS, WHETHER OR NOT SUCH AMOUNTS WERE AUTHORIZED BY YOU. If you are an iOS user via Apple’s App Store, YooNuu does not have the ability 
                    to manage any aspect of your subscription on your behalf, including, but not limited to, initiating, canceling or refunding subscriptions. Subscriptions are valid 
                    for at least thirty (30) days. Payment will be charged to your App Store account at confirmation of purchase. Your subscription automatically renews unless 
                    auto-renew is turned off at least twenty-four (24) hours before the end of the current period. Your account will be charged the then-applicable subscription 
                    price within twenty-four (24) hours prior to the end of the current period. Subscriptions may be managed and auto-renewal may be turned off by going to the Account 
                    Settings screen in the App Store app on your device after purchase. No cancellation of the current subscription is allowed during active subscription period. For 
                    step-by-step information on how to manage subscriptions, including information on canceling subscriptions, visit https://yoonuu.app/termsOfUse. You should be aware 
                    that cancelling your subscription and/or deleting the App(s) may not terminate your user account on the Service. If you cancel your subscription and/or delete the 
                    App(s) but do not take steps to deactivate your user account or take other appropriate steps, your profile may continue to be accessible to others on the Service.

                    </p>
                

                    <h3><b>9. feedback</b></h3>
                    <p>
                    By sending us any ideas, suggestions, comments, improvements, documents, proposals or other feedback, including without limitation, concerning the Service ("Feedback"), 
                    you agree that (i) your Feedback does not contain the confidential or proprietary information of third parties, (ii) we are under no obligation of confidentiality, 
                    express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, and (iv) you grant 
                    us an irrevocable, non-exclusive, royalty-free, perpetual, fully sub-licensable and transferable, worldwide license to use, disclose, reproduce, modify, publish, distribute, 
                    transfer and otherwise utilize your Feedback in any manner and for any purpose.
                    </p>
                    
                    <h3><b>10. External materials</b></h3>
                    <p>
                    The Service or users of the Service may provide links to other websites, computer or mobile device software applications, services or resources. You acknowledge and agree 
                    that the YooNuu Parties do not endorse and are not responsible for any content, advertising, products, services or other materials on or available through 
                    such sites or resources (“External Materials”). External Materials are subject to different terms and conditions and privacy policies, which you are responsible for reviewing. 
                    You further acknowledge and agree that the YooNuu Parties shall not be liable for any damage or loss caused by or resulting from use of or reliance on any 
                    External Materials. Your dealings with advertisers and other third parties who market, sell, buy or offer to sell or buy any goods or services on or through the Service, 
                    including payment for or delivery of such goods or services and any other terms, conditions, warranties or representations associated with such dealings, are solely between 
                    you and the advertiser or other third party. You agree that the YooNuu Parties shall not be liable for any loss or damage of any kind incurred as a result of 
                    any such dealings.
                    </p>
                    
                    <h3><b>11. Ambassadors</b></h3>
                    <p>
                    Certain sections of the Service may feature users who appoint themselves to serve as ambassadors to other users visiting or learning about certain locales (“Ambassadors”). 
                    You acknowledge and agree that the YooNuu Parties do not (i) endorse and are not responsible for any actions taken or information provided by Ambassadors or 
                    (ii) pre-screen, approve or otherwise monitor Ambassadors, although YooNuu reserves the right at its sole discretion to remove any user’s Ambassador status. 
                    You interact with Ambassadors at your own discretion and risk, and you shall be solely responsible for all losses and damages of any kind incurred as a result of your 
                    interactions with Ambassadors. If you serve as an Ambassador, you agree that you will serve responsibly and abide by these Terms and all applicable laws and regulations. 
                    You shall provide only truthful and accurate information, and you shall not engage in or promote any activities that risk the health or safety of any users or other parties. 
                    If you serve as an Ambassador, you agree that YooNuu may send you communications, including promotional emails, relating to your service as an Ambassador. 
                    If you do not wish to receive such communications, you must discontinue serving as an Ambassador. As set forth in Section 12, you may not make unsolicited offers, 
                    advertisements or proposals or send junk mail to other users of the Service. However, if a user asks you for a recommendation regarding a local business, you may provide a 
                    recommendation. If you do provide a recommendation, you agree that it will (i) be based upon your good-faith belief and not be false, misleading or deceptive; (ii) fully 
                    disclose any connection between you and any entity with a financial interest in the business recommended or a directly competing business and not express sentiments by or 
                    on behalf such entity; (iii) not be submitted for any form of compensation or consideration from any entity; and (iv) fully comply with applicable laws, regulations and 
                    guidance, including without limitation the Federal Trade Commission Guides Concerning the Use of Endorsements and Testimonials in Advertising.
                    </p>

                    <h3><b>12. Prohibited uses</b></h3>
                    <p>
                    You understand and hereby acknowledge and agree that you may not and warrant that you will not:

                    - Use, copy, print, modify, adapt, create derivative works from, market, deliver, rent, lease, sublicense, make, have made, assign, pledge, transfer, sell, offer to sell, 
                    import, reproduce, distribute, publicly perform, publicly display or otherwise grant rights to any of Our Technology, or any copy thereof, in whole or in part, except as 
                    expressly permitted under these Terms;
                    
                    - Reverse engineer, disassemble, decompile or translate any of Our Technology, or otherwise attempt to derive the source code, structural framework or the data records of 
                    any of Our Technology, or authorize any third party to do any of the foregoing;
                    
                    - Loan, resell or distribute any of Our Technology, or any part thereof, in any way;
                    
                    - Use the Service or any geographic location information displayed within the Service to stalk, harass, abuse, defame, threaten or defraud users of the Service, or to collect, 
                    attempt to collect, or store geographic location or other personal information about other users;
                
                    - Include offensive or pornographic materials in your Service personal profile page;
                
                    - Use the Service for any commercial or non-private use, it being understood that the Service is for personal, non-commercial use only;

                    - Use the Service for any illegal purpose, or in violation of any local, state, national, or international law or regulatory requirement, including, without limitation, laws 
                    and regulatory requirements governing intellectual property and other proprietary rights, data protection and privacy, import or export control, and equal opportunity;

                    - Make unsolicited offers, advertisements or proposals, or send junk mail, to other users of the Service. This includes, but is not limited to, unsolicited advertising, 
                    promotional materials or other solicitation material, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for 
                    signatures;
                    Impersonate any person or entity, falsely claim an affiliation with any person or entity, or access the accounts of other users of the Service;

                    - Misrepresent the source, identity or content of information transmitted via the Service;

                    - Remove, circumvent, disable, damage or otherwise interfere with security-related features of the Service, features that prevent or restrict use or copying of any content 
                    accessible through the Service, or features that enforce limitations on use of the Service;
                    Intentionally interfere with or damage operation of the Service or any user's enjoyment of them, by any means, including uploading or otherwise disseminating viruses, worms, 
                    or other malicious code;

                    - Intentionally interfere with or damage operation of the Service or any user's enjoyment of them, by any means, including uploading or otherwise disseminating viruses, worms, 
                    or other malicious code;

                    - Post, submit, publish, store, disclose, display, send, transmit, or disseminate any information or material which a reasonable person could deem to be objectionable, unseemly, 
                    offensive (including without limitation, regarding race or ethnicity), distressing, vulgar, hateful, or otherwise inappropriate, regardless of whether this material or its 
                    dissemination is unlawful;

                    - Post, submit, publish, store, disclose, display, send, transmit, or disseminate any unlawful, libelous, defamatory, threatening, harassing, intimidating, tormenting, 
                    embarrassing, scandalous, inflammatory, profane material or any other material including but not limited to any material that could give rise to any civil or criminal liability 
                    under any local, state, national, or international law or regulatory requirement;

                    - Post, submit, publish, store, disclose, display, send, transmit, or disseminate any information or material which infringes any patents, trademarks, trade secrets, copyrights, 
                    or any other proprietary or intellectual property rights;

                    - Use the Service with any products, systems, or applications installed or otherwise connected to or in communication with vehicles, or otherwise capable of vehicle navigation, 
                    positioning, dispatch, real time route guidance, fleet management, or similar applications;

                    - Attempt to gain unauthorized access to the Service, or any part of these, other accounts, computer systems or networks connected to the Service, or any part of these, through 
                    hacking, password mining or any other means, or to interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service; or

                    - Use any robot, spider, scraper or other automated means to access the Service for any purpose without our express written permission or bypass our robot exclusion headers or 
                    other measures we may use to prevent or restrict access to the Service or modify the Service in any manner or form, or use modified versions of the Service, including (without 
                    limitation) for the purpose of obtaining unauthorized access to the Service.
                    </p>
                    
                    <h3><b>13. User content</b></h3>
                    <p>
                    The Service allows the submission of information, content and materials (such as pictures, reviews, ratings, ideas, notes, concepts, creative suggestions, 
                    questions, messages and comments) by you and other users ("User Submissions"), and the hosting, disclosing and/or publishing of such User Submissions. You 
                    shall be solely responsible for your own User Submissions (“Your Content”) and the consequences of posting or publishing them. In connection with Your 
                    Content, you affirm, represent, and warrant that (i) you own, or have the necessary licenses, rights, consents, and permissions to use, and authorize 
                    YooNuu to use, all intellectual property and any other proprietary rights in and to any and all of Your Content to enable inclusion and use 
                    of Your Content in the manner contemplated by the Service and these Terms; and (ii) you have the written consent, release, and/or permission of each and 
                    every identifiable individual person in Your Content to use the name or likeness of each and every such identifiable individual person to enable inclusion 
                    and use of Your Content in the manner contemplated by the Service and these Terms. For clarity, you shall retain all of your ownership rights in Your Content. 
                    YooNuu assumes no responsibility whatsoever in connection with or arising from User Submissions. YooNuu assumes no responsibility 
                    for actively monitoring User Submissions for inappropriate or explicit content. If at any time YooNuu chooses, in its sole discretion, to monitor 
                    User Submissions, YooNuu nonetheless assumes no responsibility for the content of the User Submissions, no obligation to modify or remove any 
                    inappropriate or explicit User Submissions, and no responsibility for the conduct of the user submitting User Submissions. Further, YooNuu does 
                    not endorse and has no control over the content of User Submissions submitted by other users. YooNuu makes no warranties, express or implied, as 
                    to the content of User Submissions or the accuracy and reliability of any User Submissions.
                    </p>

                    <h5>Responsibility for your content</h5>
                    <p>
                    You alone are responsible for Your Content, and once published, it cannot always be withdrawn. You assume all risks associated with Your Content, including 
                    anyone's reliance on its quality, accuracy, or reliability, or any disclosure by you of information in Your Content that makes you personally identifiable 
                    or provides others with information to locate you or determine places that you have visited, are visiting or are planning to visit. You represent that you 
                    own, or have the necessary permissions to use and authorize the use of Your Content as described herein. You may not imply that Your Content is in any way 
                    sponsored or endorsed by YooNuu. You may expose yourself to liability if, for example, Your Content contains material that is false, 
                    intentionally misleading, or defamatory; violates any third party right, including any copyright, trademark, patent, trade secret, moral right, privacy 
                    right, right of publicity, or any other intellectual property or proprietary right; contains material that is unlawful, including illegal hate speech or 
                    pornography; exploits or otherwise harms minors; or violates or advocates the violation of any law or regulation.
                    </p>


                    <h5>Our right to use YOUR content</h5>
                    <p>
                    YooNuu does not claim ownership of Your Content. However, you grant us a perpetual, worldwide, royalty-free, non-exclusive license to use, 
                    distribute, reproduce, modify, translate, adapt, publicly perform and publicly display Your Content in connection with your use of the Service, for any
                    purpose permitted under our Privacy Policy and as necessary for posting, displaying, distributing and storing Your Content on the Service. You also grant 
                    the users of the Service the right to access Your Content in connection with their use of the Service. You understand that the technical processing and 
                    transmission of data associated with Your Content may involve (i) transmissions over various networks; and (ii) changes to conform and adapt to technical 
                    requirements of servers, connecting networks or devices.
                    </p>


                    <h5>Advertising</h5>
                    <p>
                    YooNuu and its licensees may publicly display advertisements and other information adjacent to Your Content. You are not entitled to any 
                    compensation for such advertisements. The manner, mode and extent of such advertising are subject to change without specific notice to you.
                    We reserve the right to remove, screen, edit, or reinstate User Submissions from time to time at our sole discretion and without notice to you. We have no 
                   obligation to retain or provide you with copies of Your Content, subject to our Privacy Policy regarding certain information about you, nor do we guarantee 
                   any confidentiality with respect to Your Content.
                   </p>

                    <h3><b>14. Indemnification</b></h3>
                    <p>
                    You agree to indemnify, defend, and hold the YooNuu Parties harmless from and against any and all claims, suits, actions, losses, costs, 
                    damages, and any other liabilities, including attorneys' fees, arising out of or related to your use of the Service and/or your interactions with other 
                    users of the Service, including, but not limited to, (i) your use or misuse of any geographic location information or the Service generally, (ii) any 
                    violation of the rights of any other person or entity by you, (iii) any breach or violation by you of these Terms or any law, regulation or guidance, 
                    (iv) your access to and use of the Service, including without limitation, to communicate with or meet another user in-person or to locate and attend any 
                    offline place or event or to post, submit, publish, store, disclose, display, send, transmit, or disseminate any content or materials, or (v) any negligent 
                    acts, omissions or willful misconduct by you. YooNuu reserves the right to assume the exclusive defense and control of any matter for which 
                    you are required to indemnify us, and you agree to cooperate with our defense of these claims.
                    </p>

                    <h3><b>15. Interactions with other users</b></h3>
                    <p>
                    You are solely responsible for your interactions with other users using the Service and the YooNuu Parties assume no liability for these 
                    interactions. YooNuu does not conduct criminal or other background checks on its users, but reserves the right to do so at any time. The 
                    YooNuu Parties assume no liability for your interactions with other users and make no representations or warranties as to the conduct of 
                    users and/or the information they provide and/or the statements that they make. You agree to take reasonable precautions in your interactions with other 
                    users of the Service. The YooNuu Parties are not responsible for monitoring any disputes between you and other users.

                    You will create a username and password as part of the registration process for the Service. You are responsible for maintaining the confidentiality of 
                    your password and account and all activities that occur in connection with these. You agree to immediately notify YooNuu of any actual or 
                    suspected unauthorized use of your password or account or any other actual or suspected breach of security. You agree that all information that you provide 
                    to YooNuu as part of the registration process, including, but not limited to, your name and email address, is truthful, accurate and complete.
                    </p>

                    <h3><b>16. Governing law</b></h3>
                    <p>
                        These Terms and your relationship with YooNuu under these Terms shall be governed by and construed and enforced in accordance with the 
                        United States Federal Arbitration Act, other applicable federal laws and the laws of the State of New York, without regard to conflict of laws principles. 
                        The United Nations Convention on Contracts for the International Sale of Goods is specifically excluded from application to these Terms.
                    </p>

                    <h3><b>17. BINDING ARBITRATION AND CLASS ACTION WAIVER</b></h3>
                    <p>
                    ANY DISPUTE OR CLAIM ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OR YOUR ACCESS TO OR USE OF THE SERVICE, INCLUDING, BUT NOT LIMITED TO, THE VALIDITY, 
                    APPLICABILITY OR INTERPRETATION OF THESE TERMS (EACH, A “CLAIM”), SHALL BE RESOLVED BY BINDING ARBITRATION RATHER THAN IN COURT, EXCEPT THAT YOU MAY ASSERT 
                    CLAIMS IN SMALL CLAIMS COURT IF YOUR CLAIMS QUALIFY. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.


                    The arbitration will be conducted by the American Arbitration Association (AAA) under its then-applicable rules, including (as appropriate) its Supplementary 
                    Procedures for Consumer-Related Disputes. The AAA’s rules are available at https://www.adr.org. Payment of all filing, administration and arbitrator fees will 
                    be governed by the AAA’s rules. The arbitration shall be conducted in the English language by a single independent and neutral arbitrator. For any hearing 
                    conducted in person as part of the arbitration, you agree that the hearing will be conducted in the County and State of New York or the city in the United 
                    States in which you reside. The decision of the arbitrator shall be final and binding. Judgment on the arbitral award may be entered in any court of competent 
                    jurisdiction.


                    Before submitting a Claim to arbitration pursuant to this Section 17, you must first submit the Claim to YooNuu for informal resolution through 
                    https://yoonuu.app/contact. You and YooNuu agree to work in good faith to resolve the 
                    Claim within sixty (60) days. If you and YooNuu are unable to resolve the Claim informally within sixty (60) days, then we each may submit the 
                    Claim to arbitration.


                    If you reject the last written settlement offer made by YooNuu before the arbitrator was appointed and the arbitrator awards you an amount greater 
                    than this last written settlement offer, then YooNuu will pay (i) the greater of the award or $500; (ii) your filing fees for the arbitration; 
                    and (iii) any fees, costs and expenses deemed appropriate by the arbitrator.


                    WE EACH AGREE THAT ANY DISPUTE OR CLAIM ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OR YOUR ACCESS TO OR USE OF THE SERVICE WILL BE RESOLVED ONLY ON AN 
                    INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION OR ARBITRATION. IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN IN ARBITRATION, 
                    WE EACH WAIVE ANY RIGHT TO A JURY TRIAL AND AGREE THAT SUCH CLAIM SHALL BE BROUGHT ONLY IN THE UNITED STATES DISTRICT COURT FOR THE SOUTHERN DISTRICT OF NEW YORK 
                    OR, IF FEDERAL JURISDICTION IS NOT AVAILABLE, IN THE SUPREME COURT OF NEW YORK COUNTY. YOU HEREBY SUBMIT TO THE PERSONAL JURISDICTION AND VENUE OF SUCH COURTS AND 
                    WAIVE ANY OBJECTION ON THE GROUNDS OF VENUE, FORUM NON-CONVENIENS OR ANY SIMILAR GROUNDS WITH RESPECT TO ANY SUCH PROCEEDING.


                    Notwithstanding anything to the contrary, you and YooNuu may seek injunctive relief and any other equitable remedies from any court of competent 
                    jurisdiction to protect our intellectual property rights, whether in aid of, pending or independently of the resolution of any dispute pursuant to the arbitration 
                    procedures set forth above.
                    </p>

                    <h3><b>18. INFORMATION OR COMPLAINTS</b></h3>
                    <p>
                    We encourage you to submit a question or complaint regarding the Service, including without limitation, regarding any content, by submitting a support request at 
                    https://yoonuu.app/contact.

                    </p>

                    <h3><b>19. MISCELLANEOUS</b></h3>
                    <p>
                    If any provision of these Terms is held to be invalid or unenforceable under applicable law, then such provision shall be construed, limited, modified or, if necessary, 
                    severed to the extent necessary to eliminate its invalidity or unenforceability, without in any way affecting the remaining parts of these Terms. Such provision shall be 
                    ineffective to the extent of such invalidity or unenforceability only, without in any way affecting the remaining parts of these Terms.


                    A provision of these Terms may be waived only by a written instrument executed by the party entitled to the benefit of such provision. The failure of any party at any time 
                    to require performance of any provision of these Terms shall in no manner affect such party's right at a later time to enforce the same. A waiver of any breach of any 
                    provision of these Terms shall not be construed as a continuing waiver of other breaches of the same or other provisions of these Terms.


                    These Terms shall be binding upon and inure to the benefit of the parties hereto and their respective successors and permitted assigns. You may not assign any of your 
                    rights or obligations under these Terms to another party without the express written consent of YooNuu.


                    The parties acknowledge that these Terms are concluded between you and YooNuu only, and not with Apple or the owner or operator of any other applicable 
                    App Store through which you may have downloaded the App(s) (the “App Store Owner”), and the App Store Owner is not responsible for the App(s) and the contents thereof. 
                    The App Store Owner has no obligation whatsoever to furnish any maintenance and support services with respect to the App(s). YooNuu, not the App Store 
                    Owner, is responsible for addressing any claims from you or any third party relating to the App(s) or your possession and/or use of the App(s), including, but not 
                    limited to, product liability claims, any claim that the App(s)s fails to conform to any applicable legal or regulatory requirement and claims arising under consumer 
                    protection or similar legislation. The App Store Owner and its subsidiaries are third party beneficiaries of these Terms, and shall have the right (and will be deemed 
                    to have accepted the right) to enforce these Terms against you as a third party beneficiary hereof.


                    The communications between you and us use electronic means, whether you use the Service or send emails to us, or whether we post notices on the Service or communicate 
                    with you via email. For contractual purposes, you (i) consent to receive communications from us in an electronic form; and (ii) agree that all terms and conditions, 
                    agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy 
                    if they were in a hardcopy writing. The foregoing does not affect your non-waivable rights.


                    These Terms constitute the complete and exclusive statement of the agreement between YooNuu and you with respect to the subject matter hereof and supersede 
                    any proposal or prior or contemporaneous agreement, oral or written, and any other communications between the parties in relation to the subject matter of these Terms.


                    You represent and warrant that you are not (i) located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as 
                    a “terrorist supporting” country; and (ii) listed on any U.S. Government list of prohibited or restricted parties, including, but not limited to, the Specially Designated 
                    Nationals List.


                    YOU AND YooNuu AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. 
                    OTHERWISE, SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED.
                                
                    </p>       


                </div>

            </div>

        </div>


    );
}
  
export default  TermsOfUse;