import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import { SocialIcon } from 'react-social-icons'
import 'bootstrap/dist/css/bootstrap.min.css'

function Contact(){
    return (
  
        
        <div className="container-fluid">
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Oswald" />

            <br/><br/>
            <div className="subcontainer">


               

            

                <div class="row" id="subcontainer-row">

                    <div class="col-sm">
                        <br/>
                        <div className="headingB">
                             QUESTIONS OR CONCERNS? 

                        </div>
                       



                    </div>
                    <div class="col-sm">

                        <br/>
                        <h3><b>Support</b></h3>

                        <h5>
                            Need help with profile or discussion forum related issues contact us at
                        </h5>
                        <a href="support@yoonuu.app" id="email">support@yoonuu.app</a>

                        <br/><br/>


                        <h3><b>Advertise</b></h3>

                        <h5>
                        Want to advertise with YooNuu? Please submit all inquiries at 
                        </h5>
                        <a href="advertise@yoonuu.app" id="email">advertise@yoonuu.app</a>
                       

                        <br/><br/>

                        <h3><b>Careers</b></h3>

                        <h5>
                        Want to contribute to our mission? Send your linkedin page or resume to 
                        </h5>
                        <a href="careers@yoonuu.app" id="email">careers@yoonuu.app</a>


                        <br/><br/>

                        <h3><b>Press</b></h3>

                        <h5>
                        Interested in featuring YooNuu in your next article? 
                        </h5>
                        <a href="press@yoonuu.app" id="email">press@yoonuu.app</a>




                        <br/><br/>

                        
                        <h3><b>Social</b></h3>

                        <h5>
                        Do you have general inquiries? Send us a message on instagram
                        </h5>
                        <div class="row">

       

                        <div class="col-2"><SocialIcon url="https://www.instagram.com/yoonuu.app/" bgColor="black" fgColor="white"/></div>

                        </div>

                    </div>

                
                </div>
            
            </div>

        </div>
      
  
  
    );
}
  
export default  Contact;