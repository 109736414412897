import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import Ashie from "../images/ashie2.jpg"
import 'bootstrap/dist/css/bootstrap.min.css'
import { SocialIcon } from 'react-social-icons'

function Team(){
    return (
  
        
        <div className="container">
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Oswald" />
            <div className="subcontainer">


                <div class="row" id="subcontainer-row">
                   
                    <div class="col-sm" >
                      

                            <div className="photo">
                                <img src={Ashie} alt=""/>
                            </div>
                            <h5>Ashie Annan</h5>

                            <p>Engineer/Scientist | Music Producer | Vegan </p>

                            <div><i>Follow Me:</i></div>

                            <div class="row">

                                <div class="col-2"><SocialIcon url="https://www.linkedin.com/in/carlashieannan/" bgColor="black" fgColor="white"/></div>

                                <div class="col-2"><SocialIcon url="https://www.instagram.com/gam.ashie/" bgColor="black" fgColor="white"/></div>

                                <div class="col-2"><SocialIcon url="https://x.com/gamashie" bgColor="black" fgColor="white"/></div>

                                <div class="col-2"><SocialIcon url="https://youtube.com/@gamashie87" bgColor="black" fgColor="white"/></div>

                                 {/*<div class="col-2"><SocialIcon url="https://www.facebook.com/profile.php?id=61559373999616" bgColor="black" fgColor="white"/></div> */}

                                {/*<div class="col-2"><SocialIcon url="https://www.reddit.com/user/NunguaNuu/" bgColor="black" fgColor="white"/></div>*/}

                            </div>
                            
                    
                            
                            <br/>
                            {/*
                            <div>
                                <div class="row py-2">
                                    <div class="col-4">
                                        <div><a href="https://www.instagram.com/nungua.nuu/" class="btn btn-info btn-dark w-100" role="button">Instagram</a></div>
                                    </div>
                                    <div class="col-4">
                                        <div><a href="https://www.linkedin.com/in/carlashieannan" class="btn btn-info btn-dark w-100" role="button">LinkedIn</a></div>
                                    </div>
                                    <div class="col-4">
                                        <div><a href="https://twitter.com/vegan_engineer" class="btn btn-info btn-dark w-100" role="button">Twitter</a></div>
                                    </div>
                                </div>
                  
                            </div>
                            */}

                        
                    </div>

                    <div class="col-sm" >
                      

                    </div>

               
                 

                </div>

            </div> 

        </div>

    

      
  
  
    );
}
  
export default  Team;