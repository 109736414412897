import React from "react"
import screenshot1 from "../images/screenshot1.png"
import screenshot2 from "../images/screenshot2.png"
import screenshot3 from "../images/screenshot3.png"
import 'bootstrap/dist/css/bootstrap.min.css'

function Careers(){
    return (
  
        
        <div className="container-fluid">
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Oswald" />
            <div className="sub-container">

            

                <div class="row" id="subcontainer-row">

                    <div class="col-sm-4">
                        <br/>
                        <br/>
                        <div className="headingB">INTERESTED IN WORKING AT YOONUU?</div>
                    </div>
                    <div class="col-sm-8">
                        <br/>
                        <br/>
                        <div className="heading2">
                        We are still raising funds but we plan to hire for remote positions in mobile (ios/android) and web development, UI design, marketing as well as vetters (to regulate 
                        sign up of new  members and keep YooNuu a safe space). YooNuu will be 100% run by queer african diasporans. 
                        In the meantime, please email us at careers@yoonuu.app and include a link to your linkedin page and/or your resume if you feel you can contribute to YooNuu's mission 
                        and we will reach out to you in the future.

                   


                        </div>

                        <br/>


                    
                    </div>
                </div>
            </div>

        </div>
      
  
  
    );
}
  
export default  Careers;